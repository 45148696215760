body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.content-wrap {
  flex: 1;
}


.footer {
  position: relative;
  width: 100%;
  bottom: 0;
  height: 50px;
  /* Aggiungi altri stili del footer qui */
}

.chipContainer{
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}